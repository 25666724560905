<template>
  <div class="desktopTerminals">
    <div class="title" ref="desktopTerminals">
      <div class="big">Desktop Terminals</div>
    </div>
    <div class="ACESeries">
      <div class="itemVolley1" @click="goRouter('/Volley1')">
        <div class="img"></div>
        <div class="bottom">
          <div class="title">Volley 1</div>
          <div class="text">Smart Desktop</div>
          <div class="text">Terminal</div>
        </div>
      </div>
      <div class="itemOne" @click="goRouter('/ACE1')">
        <div class="img"></div>
        <div class="bottom">
          <div class="title">ACE 1</div>
          <div class="text">Smart Desktop</div>
          <div class="text">Terminal</div>
        </div>
      </div>
      <div class="itemTwo" @click="goRouter('/ACE1Pro')">
        <div class="img"></div>
        <div class="bottom">
          <div class="title">ACE 1 Pro</div>
          <div class="text">Smart Desktop</div>
          <div class="text">Terminal</div>
        </div>
      </div>
      <div class="itemThree" @click="goRouter('/ACE2')">
        <div class="img"></div>
        <div class="bottom">
          <div class="title">ACE 2</div>
          <div class="text">Smart Desktop</div>
          <div class="text">Terminal</div>
        </div>
      </div>
      <div class="itemFour" @click="goRouter('/D1')">
        <div class="img"></div>
        <div class="bottom">
          <div class="title">D1</div>
          <div class="text">Smart Desktop</div>
          <div class="text">Terminal</div>
        </div>
      </div>
      <div class="itemFive" @click="goRouter('/Y1')">
        <div class="img"></div>
        <div class="bottom">
          <div class="title">Y1</div>
          <div class="text">Smart Desktop</div>
          <div class="text">Terminal</div>
        </div>
      </div>
    </div>
    <div class="ESeries" ref="smartWeighing">
      <div class="bigTitle">Smart Weighing</div>
      <div class="itemBox">
        <div class="itemOne" @click="goRouter('/ET7')">
          <div class="img"></div>
          <div class="bottom">
            <div class="title">ET7</div>
            <div class="text">Smart Weighing</div>
            <div class="text">Terminal</div>
          </div>
        </div>
      </div>
    </div>
    <div class="Kiosks" ref="Kiosks">
      <div class="bigTitle">Kiosks</div>
      <div class="itemBox">
        <div class="itemOne" @click="goRouter('/MK215')">
          <div class="img"></div>
          <div class="bottom">
            <div class="title">MK 215</div>
            <div class="text">Smart Kiosk</div>
            <div class="text">Terminal</div>
          </div>
        </div>
      </div>
    </div>
    <div class="Peripherals" ref="Peripherals">
      <div class="bigTitle">Peripherals</div>
      <!-- <div class="smallTitle">MK Series</div> -->
      <div class="itemBox">
        <div class="itemOne" @click="goRouter('/9533Series')">
          <div class="img"></div>
          <div class="bottom">
            <div class="title">Agility 9533 Series</div>
            <div class="text">2D Wireless</div>
            <div class="text">Barcode Scanner</div>
          </div>
        </div>
        <div class="itemTwo" @click="goRouter('/9520Series')">
          <div class="img"></div>
          <div class="bottom">
            <div class="title">Agility 9520 Series</div>
            <div class="text">2D Wired</div>
            <div class="text">Barcode Scanner</div>
          </div>
        </div>
        <div class="itemThree" @click="goRouter('/A90')">
          <div class="img"></div>
          <div class="bottom">
            <div class="title">Agility A-90</div>
            <div class="text">2D Desktop</div>
            <div class="text">Barcode Scanner</div>
          </div>
        </div>
        <div class="itemTwo" style="opacity: 0"></div>
      </div>
    </div>
    <div class="Printer" ref="Printer">
      <div class="bigTitle">Printer</div>
      <div class="itemBox">
        <div class="itemOne" @click="goRouter('/Sprint800')">
          <div class="img"></div>
          <div class="bottom">
            <div class="title">Sprint 800</div>
            <div class="text">Desktop Printer</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 接收方页面的组件中
import { EventBus } from '@/utils/eventBus.js'
export default {
  data() {
    return {
      path: ''
    }
  },
  mounted() {
    this.path = this.$route.path
    EventBus.$on(this.path, data => {
      this.toLocal(data)
    })
  },
  methods: {
    toLocal(Id) {
      //锚点存在跳转
      if (Id) {
        const top1 = this.$refs[Id].getBoundingClientRect().top
        // 获取滚动条的位置
        const top2 =
          document.body.scrollTop || document.documentElement.scrollTop
        // 计算元素距视窗的实际高度
        const top = top1 + top2
        // 滚动到相应位置
        window.scrollTo(0, top - localStorage.getItem('header'))
      }
    },
    goRouter(val) {
      this.$router.push({ path: val })
    }
  },
  beforeDestroy() {
    EventBus.$off(this.path)
  }
}
</script>

<style lang="less" scoped>
.desktopTerminals {
  padding-top: 28px;
  background: rgba(0, 0, 0, 1);
  .title {
    text-align: center;
    .big {
      color: rgba(255, 255, 255, 1);
      font-size: 20px;
      font-weight: 700;
      line-height: 28.96px;
    }
  }
  .bottom {
    height: 57.67px;
    .title {
      text-align: center;
      margin: 0px 0px 4.53px;
      color: rgba(0, 92, 255, 1);
      font-size: 16px;
      font-weight: 500;
      line-height: 23.17px;
    }
    .text {
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-size: 12px;
      line-height: 17.38px;
      font-weight: 500;
    }
  }
  .ACESeries {
    margin: 0px auto;
    width: 339px;
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    .itemVolley1 {
      cursor: pointer;
      margin-top: 20px;
      width: 160px;
      height: 200px;
      border-radius: 20px;
      background: rgba(46, 50, 59, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        margin: 26px 0px 15px 0px;
        transition: all 0.3s;
        height: 82px;
        width: 91px;
        background: url('../../../assets/下拉栏配图/Volley1/下拉菜单栏配图2.png')
          no-repeat center;
        background-size: cover;
        &:hover {
          transform: scale(1.05);
          background: url('../../../assets/下拉栏配图/Volley1/下拉菜单栏配图1.png')
            no-repeat center;
          background-size: cover;
        }
      }
    }
    .itemOne {
      margin-top: 20px;
      cursor: pointer;
      width: 160px;
      height: 200px;
      border-radius: 20px;
      background: rgba(46, 50, 59, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        margin: 17.71px 0px 12.86px 0px;
        transition: all 0.3s;
        height: 90.7px;
        width: 159px;
        background: url('../../../assets/下拉栏配图/ACE 1/透明底2.png')
          no-repeat center;
        background-size: cover;
        &:hover {
          transform: scale(1.05);
          background: url('../../../assets/下拉栏配图/ACE 1/透明底2.png')
            no-repeat center;
          background-size: cover;
        }
      }
    }
    .itemTwo {
      margin-top: 20px;
      cursor: pointer;
      width: 160px;
      height: 200px;
      border-radius: 20px;
      background: rgba(46, 50, 59, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        margin: 10px 0px 10.76px 0px;
        transition: all 0.3s;
        height: 100.24px;
        width: 90.49px;
        background: url('../../../assets/下拉栏配图/ACE 1 Pro/透明底2.png')
          no-repeat center;
        background-size: cover;
        &:hover {
          transform: scale(1.05);
          background: url('../../../assets/下拉栏配图/ACE 1 Pro/透明底2.png')
            no-repeat center;
          background-size: cover;
        }
      }
    }
    .itemThree {
      margin-top: 20px;
      cursor: pointer;
      width: 160px;
      height: 200px;
      border-radius: 20px;
      background: rgba(46, 50, 59, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        margin: 22.06px 0px 8.22px 0px;
        transition: all 0.3s;
        width: 119px;
        height: 91px;
        background: url('../../../assets/下拉栏配图/ACE 2/1.png') no-repeat
          center;
        background-size: cover;
        &:hover {
          transform: scale(1.05);
          background: url('../../../assets/下拉栏配图/ACE 2/1.png') no-repeat
            center;
          background-size: cover;
        }
      }
    }
    .itemFour {
      margin-top: 20px;
      cursor: pointer;
      width: 160px;
      height: 200px;
      border-radius: 20px;
      background: rgba(46, 50, 59, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        margin: 18px 0px 4.14px 0px;
        transition: all 0.3s;
        height: 103.59px;
        width: 98.86px;
        background: url('../../../assets/下拉栏配图/D1/1.png') no-repeat center;
        background-size: cover;
        &:hover {
          transform: scale(1.05);
          background: url('../../../assets/下拉栏配图/D1/1.png') no-repeat
            center;
          background-size: cover;
        }
      }
    }
    .itemFive {
      margin-top: 20px;
      cursor: pointer;
      width: 160px;
      height: 200px;
      border-radius: 20px;
      background: rgba(46, 50, 59, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        margin: 12px 0px 9.94px 0px;
        transition: all 0.3s;
        height: 97.33px;
        width: 94.93px;
        background: url('../../../assets/下拉栏配图/Y1/1.png') no-repeat center;
        background-size: cover;
        &:hover {
          transform: scale(1.05);
          background: url('../../../assets/下拉栏配图/Y1/1.png') no-repeat
            center;
          background-size: cover;
        }
      }
    }
  }
  .DSeries {
    .smallTitle {
      text-align: center;
      margin: 52px 0px 34px 0px;
      color: rgba(0, 92, 255, 1);
      font-size: 24px;
      font-weight: 700;
      line-height: 34.75px;
    }
    .itemBox {
      display: flex;
      justify-content: center;
      .itemOne {
        cursor: pointer;
        width: 285px;
        height: 320px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 51px 0px 21.38px 0px;
          transition: all 0.3s;
          height: 124.62px;
          width: 223.37px;
          background: url('../../../assets/下拉栏配图/D1/1.png') no-repeat
            center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background: url('../../../assets/下拉栏配图/D1/1.png') no-repeat
              center;
            background-size: cover;
          }
        }
      }
    }
  }
  .YSeries {
    .smallTitle {
      text-align: center;
      margin: 52px 0px 34px 0px;
      color: rgba(0, 92, 255, 1);
      font-size: 24px;
      font-weight: 700;
      line-height: 34.75px;
    }
    .itemBox {
      display: flex;
      justify-content: center;
      .itemOne {
        cursor: pointer;
        width: 285px;
        height: 320px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 48px 0px 18.1px 0px;
          transition: all 0.3s;
          height: 130.9px;
          width: 140.03px;
          background: url('../../../assets/下拉栏配图/Y1/1.png') no-repeat
            center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background: url('../../../assets/下拉栏配图/Y1/1.png') no-repeat
              center;
            background-size: cover;
          }
        }
      }
    }
  }
  .ESeries {
    margin: 0px auto;
    width: 339px;
    .bigTitle {
      text-align: center;
      margin: 30px 0px 0px 0px;
      color: rgba(255, 255, 255, 1);
      font-size: 20px;
      font-weight: 700;
      line-height: 28.96px;
    }
    .itemBox {
      display: flex;
      justify-content: start;
      .itemOne {
        cursor: pointer;
        margin-top: 20px;
        width: 160px;
        height: 200px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 3px 0px 0px 0px;
          transition: all 0.3s;
          height: 118px;
          width: 86px;
          background: url('../../../assets/下拉栏配图/ET 7/3.png') no-repeat
            center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background: url('../../../assets/下拉栏配图/ET 7/3.png') no-repeat
              center;
            background-size: cover;
          }
        }
      }
    }
  }
  .Kiosks {
    margin: 0px auto;
    width: 339px;
    .bigTitle {
      text-align: center;
      margin: 30px 0px 0px 0px;
      color: rgba(255, 255, 255, 1);
      font-size: 20px;
      font-weight: 700;
      line-height: 28.96px;
    }
    .itemBox {
      display: flex;
      justify-content: start;
      .itemOne {
        cursor: pointer;
        margin-top: 20px;
        width: 160px;
        height: 200px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 3px 0px 5.27px 0px;
          transition: all 0.3s;
          height: 118px;
          width: 86px;
          background: url('../../../assets/下拉栏配图/MK 215/变量 2.png')
            no-repeat center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background: url('../../../assets/下拉栏配图/MK 215/变量 2.png')
              no-repeat center;
            background-size: cover;
          }
        }
      }
    }
  }
  .Peripherals {
    .bigTitle {
      text-align: center;
      margin: 30px 0px 0px 0px;
      color: rgba(255, 255, 255, 1);
      font-size: 20px;
      font-weight: 700;
      line-height: 28.96px;
    }
    .itemBox {
      display: flex;
      justify-content: center;
      flex-flow: wrap;
      .itemOne {
        margin-top: 20px;
        margin-right: 20px;
        cursor: pointer;
        width: 160px;
        height: 200px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 10.98px 0px 3px 0px;
          transition: all 0.3s;
          height: 108px;
          width: 108px;
          background: url('../../../assets/下拉栏配图/Agility9533Series/下拉栏配图.png')
            no-repeat center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background-size: cover;
          }
        }
      }
      .itemTwo {
        margin-top: 20px;
        cursor: pointer;
        width: 160px;
        height: 200px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 10.98px 0px 3px 0px;
          transition: all 0.3s;
          width: 108px;
          height: 108px;
          background: url('../../../assets/下拉栏配图/Agility9520Series/正面.png.png')
            no-repeat center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background-size: cover;
          }
        }
      }
      .itemThree {
        margin-top: 20px;
        margin-right: 20px;
        cursor: pointer;
        width: 160px;
        height: 200px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 10.98px 0px 3px 0px;
          transition: all 0.3s;
          width: 108px;
          height: 108px;
          background: url('../../../assets/下拉栏配图/A90/产品导航栏.png')
            no-repeat center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background-size: cover;
          }
        }
      }
    }
  }
  .Printer {
    margin: 0px auto;
    width: 339px;
    .bigTitle {
      text-align: center;
      margin: 30px 0px 0px 0px;
      color: rgba(255, 255, 255, 1);
      font-size: 20px;
      font-weight: 700;
      line-height: 28.96px;
    }
    .itemBox {
      display: flex;
      justify-content: start;
      .itemOne {
        cursor: pointer;
        margin-top: 20px;
        width: 160px;
        height: 200px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 10.98px 0px 3px 0px;
          transition: all 0.3s;
          width: 108px;
          height: 108px;
          background: url('../../../assets/Sprint800配图/正面.png') no-repeat
            center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background: url('../../../assets/Sprint800配图/正面.png') no-repeat
              center;
            background-size: cover;
          }
        }
      }
    }
  }
}
</style>
